/*
 *'use strict';
 */

import 'core-js/es/object';
import 'core-js/es/typed-array';
import 'core-js/es/object';
import 'core-js/es/set';
import { Amplify, Auth } from 'aws-amplify';

import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import getAmplifyConfiguration from './amplifyConfiguration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let auth = getAmplifyConfiguration();

Amplify.configure({
    Auth: auth,
});

const FETCH_STATUS = {
    IDLE: 'IDLE',
    PENDING: 'PENDING',
    AWAITING_CONFIRMATION: 'AWAITING_CONFIRMATION',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
};

const defaultPasswordValidation = {
    length: {
        valid: false,
        regex: /^.{8,}$/,
    },
    capital: {
        valid: false,
        regex: /[A-Z]/,
    },
    number: {
        valid: false,
        regex: /[0-9]/,
    },
    special_character: {
        valid: false,
        regex: /[= + - ^ $ * . \[ \] { } ( ) ? " ! @ # % & \/ \ , > < ' : ; | _ ~ `]/,
    },
};

const ForgotPassword = (props) => {
    const [username, setUsername] = useState(props.username || '');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [fetchStatus, setFetchStatus] = useState(FETCH_STATUS.IDLE);
    const [message, setMessage] = useState(null);
    const [passwordValidation, setPasswordValidation] = useState(defaultPasswordValidation);

    const handleRequestPasswordClick = (e) => {
        e.preventDefault();
        setFetchStatus(FETCH_STATUS.PENDING);
        setMessage({ text: 'Please enter the verification code that has been sent to your email.', type: 'success' });

        Auth.forgotPassword(username)
            .then((data) => {
                setFetchStatus(FETCH_STATUS.AWAITING_CONFIRMATION);
            })
            .catch((err) => {
                setMessage({ text: 'An error occurred while trying to reset your password', type: 'danger' });
            });
    };

    const handleResetPasswordClick = () => {
        setMessage(null);

        Auth.forgotPasswordSubmit(username, code, password)
            .then(() => {
                setFetchStatus(FETCH_STATUS.SUCCESS);
            })
            .catch((err) => {
                setMessage({ text: err.message, type: 'danger' });
                setFetchStatus(FETCH_STATUS.AWAITING_CONFIRMATION);
            });
    };

    const handlePasswordChange = (password) => {
        setPassword(password);

        const validationResults = { ...passwordValidation };
        for (const [key, rule] of Object.entries(passwordValidation)) {
            validationResults[key].valid = RegExp(rule.regex).test(password);
        }

        setPasswordValidation(validationResults);
    };

    const handleResetFormClick = () => {
        setMessage(null);
        setCode(null);
        setPassword(null);
        setFetchStatus(FETCH_STATUS.IDLE);
    };

    const isSuccessStatusMessage = () => {
        let successMessage = (
            <>
                Your password has been reset. Please <a href="/signin">log in</a>.
            </>
        );

        if (props.fromChangePassword && !props.fromAccountCreation) {
            successMessage = 'Your password has been changed.';
        } else if (props.fromAccountCreation) {
            successMessage = (
                <>
                    Your password has been changed. Please <a href="/signin">log in</a>.
                </>
            );
        }

        return successMessage;
    };

    return (
        <>
            {[FETCH_STATUS.IDLE, FETCH_STATUS.PENDING].includes(fetchStatus) && (
                <>
                    <Form onSubmit={handleRequestPasswordClick} id="idle-pending-status">
                        {message && <div className={`alert alert-${message.type ?? 'default'}`}>{message.text}</div>}

                        {(props.fromChangePassword || props.fromAccountCreation) && (
                            <div className="alert alert-default">
                                To change your password, you will need to receive a verification code to confirm you own
                                this account.
                            </div>
                        )}

                        <Form.Group size="lg" controlId="inputEmail">
                            <InputGroup className="mb-3">
                                <InputGroup.Text className="forgot-password-icon-parent input-group-addon">
                                    <i className="fa fa-user"></i>
                                </InputGroup.Text>

                                <Form.Control
                                    autoFocus
                                    type="email"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Username"
                                />
                            </InputGroup>
                        </Form.Group>

                        <div className="d-grid gap-2">
                            <Button
                                id="btnRequestPassword"
                                size="lg"
                                type="button"
                                className="forgot-password-button"
                                onClick={handleRequestPasswordClick}
                            >
                                {props.fromChangePassword || props.fromAccountCreation
                                    ? 'Send Verification Code'
                                    : 'Request Password!'}
                            </Button>
                        </div>

                        {!props.fromChangePassword && (
                            <div className="d-grid gap-2">
                                <Button
                                    id="btnRememberPassword"
                                    variant="outline-secondary"
                                    size="lg"
                                    type="button"
                                    onClick={() => (window.location.href = '/')}
                                >
                                    I remember my password!
                                </Button>
                            </div>
                        )}
                    </Form>
                </>
            )}

            {fetchStatus === FETCH_STATUS.AWAITING_CONFIRMATION && (
                <>
                    <Form action="/" onSubmit={() => handleResetPasswordClick()} id="formAwaitingConfirmation">
                        {message && <div className={`alert alert-${message.type ?? 'default'}`}>{message.text}</div>}
                        <Form.Group size="lg" controlId="formCode">
                            <InputGroup className="mb-3">
                                <InputGroup.Text className="forgot-password-icon-parent input-group-addon">
                                    <FontAwesomeIcon icon="shield-halved" />
                                </InputGroup.Text>

                                <Form.Control
                                    id="inputVerificationCode"
                                    type="code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    placeholder="Verification code"
                                />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group size="lg" controlId="formNewPassword">
                            <InputGroup className="mb-3">
                                <InputGroup.Text className="forgot-password-icon-parent input-group-addon">
                                    <i className="fa fa-key"></i>
                                </InputGroup.Text>

                                <Form.Control
                                    id="inputNewPassword"
                                    autoFocus
                                    type="password"
                                    value={password}
                                    onChange={(e) => handlePasswordChange(e.target.value)}
                                    placeholder="New password"
                                />
                            </InputGroup>
                        </Form.Group>

                        <div>
                            Passwords are required to meet the following requirements:
                            <ul style={{ listStyleType: 'none' }}>
                                <li>
                                    {passwordValidation.length.valid ? (
                                        <FontAwesomeIcon
                                            icon="fa fa-check"
                                            className="forgot-password-checkmark green"
                                        />
                                    ) : (
                                        <FontAwesomeIcon icon="fa fa-xmark" className="forgot-password-x red" />
                                    )}
                                    Your password must have at least 8 characters.
                                </li>
                                <li>
                                    {passwordValidation.capital.valid ? (
                                        <FontAwesomeIcon
                                            icon="fa fa-check"
                                            className="forgot-password-checkmark green"
                                        />
                                    ) : (
                                        <FontAwesomeIcon icon="fa fa-xmark" className="forgot-password-x red" />
                                    )}
                                    Your password must have at least 1 capitalized letter.
                                </li>
                                <li>
                                    {passwordValidation.number.valid ? (
                                        <FontAwesomeIcon
                                            icon="fa fa-check"
                                            className="forgot-password-checkmark green"
                                        />
                                    ) : (
                                        <FontAwesomeIcon icon="fa fa-xmark" className="forgot-password-x red" />
                                    )}
                                    Your password must have at least 1 number.
                                </li>
                                <li>
                                    {passwordValidation.special_character.valid ? (
                                        <FontAwesomeIcon
                                            icon="fa fa-check"
                                            className="forgot-password-checkmark green"
                                        />
                                    ) : (
                                        <FontAwesomeIcon icon="fa fa-xmark" className="forgot-password-x red" />
                                    )}
                                    Your password must have at least 1 special character (!#$ etc.)
                                </li>
                            </ul>
                        </div>

                        <div className="d-grid gap-2">
                            <Button
                                id={
                                    props.fromChangePassword || props.fromAccountCreation
                                        ? 'btnChangePassword'
                                        : 'btnResetPassword'
                                }
                                size="lg"
                                type="button"
                                className="forgot-password-button"
                                onClick={() => handleResetPasswordClick()}
                                disabled={Object.entries(passwordValidation).some(([, rule]) => rule.valid !== true)}
                            >
                                {props.fromChangePassword || props.fromAccountCreation
                                    ? 'Change password'
                                    : 'Reset password'}
                            </Button>
                        </div>
                    </Form>
                </>
            )}

            {fetchStatus === FETCH_STATUS.SUCCESS && (
                <>
                    <div style={{ textAlign: 'center' }} className="alert alert-success">
                        {isSuccessStatusMessage()}
                    </div>

                    <div className="d-grid gap-2">
                        <Button
                            id="btnReset"
                            size="lg"
                            type="button"
                            className="forgot-password-button"
                            onClick={() => handleResetFormClick()}
                        >
                            Reset Form
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

export default ForgotPassword;
